import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
//import { Container, Row, Col } from 'react-bootstrap'
import styled from "styled-components";
import {
  FaTwitter,
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaExternalLinkSquareAlt
} from "react-icons/fa";
import { colors } from "../../vars/palette";
import HiddenAccessibilityText from "./HiddenAccessibilityText";
import { SocialMediaIcons } from "../../_testing/src";

const Wrap = styled.div`
  a {
    display: inline-block;
    color: ${colors.hex("white")};
    font-size: 2rem;
    margin: 0 3px;
  }
`;

interface SocialLink {
  title: string;
  url: string;
}
interface SocialObject {
  name: string;
  link: string;
  icon?: React.ReactElement;
  isIncluded?: boolean;
}

const platforms = {
  facebook: <FaFacebookSquare />,
  twitter: <FaTwitter />,
  instagram: <FaInstagram />,
  linkedin: <FaLinkedin />,
  pinterest: <></>,
  youtube: <></>
};

const BasicSocialsRow = () => {
  // const socialsData = [];
  const socialsData: SocialLink[] = useStaticQuery(graphql`
    query {
      contentfulGlobal(title: { eq: "Watermark Contact Info" }) {
        links {
          title: linkText
          url
        }
      }
    }
  `).contentfulGlobal.links;
  return (
    <Wrap>
      <SocialMediaIcons socials={socialsData} platforms={platforms} />
    </Wrap>
  );
  // console.log('socialsData: ', socialsData);
  // let formattedData: SocialObject[] = [];
  // socialsData.forEach((social) => {
  //   let object: SocialObject = { name: '', link: '' };
  //   object.name = social.linkText;
  //   object.link = social.url;
  //   switch (object.name) {
  //     case "Twitter":
  //       object.icon = <FaTwitter />;
  //       object.isIncluded = true;
  //       break;
  //     case "Facebook":
  //       object.icon = <FaFacebookSquare />;
  //       object.isIncluded = true;
  //       break;
  //     case "Instagram":
  //       object.icon = <FaInstagram />;
  //       object.isIncluded = true;
  //       break;
  //     case "LinkedIn":
  //       object.icon = <FaLinkedin />;
  //       object.isIncluded = true;
  //       break;
  //     default:
  //       object.icon = <FaExternalLinkSquareAlt />;
  //       object.isIncluded = false;
  //       break;
  //   }
  //   formattedData.push(object);
  // });
  // return (
  //   <Wrap>
  //     {formattedData.map((social, i) => {
  //       return social.isIncluded ? (
  //         <a
  //           href={social.link}
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           key={`social-` + i}>
  //           {social.icon}
  //           <HiddenAccessibilityText text={`link to Watermark's ${social.name} profile`} />
  //         </a>
  //       ) : null;
  //     })}
  //   </Wrap>
  // );
};

export default BasicSocialsRow;

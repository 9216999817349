import * as React from "react";
import Layout from "../components/layout/MainLayout";
// import WMKLogo from "../components/common/WMKLogo";
import { WmkSeo } from 'wmk-seo'
// import { Col, Container, Row } from "react-bootstrap";
import { twitter, baseUrl, siteTitle } from "../vars/ThemeOptions";
import LinksLandingPage from "../components/routes/LinksLP";
import { graphql, useStaticQuery } from "gatsby";

const IndexPage = () => {
  const pageData = useStaticQuery(query).contentfulPages;
  // console.log('pageData: ', pageData);
  return (
  <Layout>
    <WmkSeo.Meta
      title={`Learn about Watermark`}
      path={"/"}
      slug={"/"}
      //description={description}
      ogImageUrl={""}
      twitterImageUrl={""} 
      twitterHandle={twitter}
      siteTitle={siteTitle}
      baseUrl={baseUrl}
    />
    {/* <Container style={{ height: "100vh" }}>
      <Row style={{ height: "100vh" }}>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WMKLogo />
        </Col>
      </Row>
    </Container> */}
    <LinksLandingPage data={pageData} />
  </Layout>
)};

export default IndexPage;

const query = graphql`
  query {
    contentfulPages(title: {eq: "Links"}) {
      ...NodePageFields
    }
  }
`
import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors, WMKGradient2, WMKGradient3 } from "../../vars/palette";
import WMKLogo from "../common/WMKLogo";
import BasicSocialsRow from "../common/BasicSocialsRow";
import { PageFields } from "../../node/fragments/NodePageFields";
import { GtmDataLayer } from "wmk-link";

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  ${WMKGradient2.grad()}
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    &.col {
      flex-direction: column;
    }
  }
  p.at {
    color: ${colors.hex("white")};
    font-size: 1.5rem;
    font-weight: 500;
  }
  @media only screen and (min-width: 992px) {
    .mobile-only {
      display: none;
    }
  }
  @media only screen and (max-width: 991px) {
    ${WMKGradient3.grad()}
    height: 100%;
    padding: 2rem;
    .desktop-only {
      display: none;
    }
    .mobile-only {
      margin-top: 1rem;
    }
  }
`;

const LogoWrap = styled.div`
  background: ${colors.hex("white")};
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  padding: 1.75rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // transform: scale(1);
  // transition: all .3s ease;
  // &:hover {
  //   transform: scale(1.1);
  //   transition: all .3s ease;
  // }
`;

const Link = styled.a`
  width: 100%;
  padding: 1.25rem 0;
  margin: 10px 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.hex("black")};
  background: ${colors.hex("white")};
  border-radius: 100px;
  transform: scale(1);
  transition: all 0.3s ease;
  &:hover {
    color: ${colors.hex("black")};
    text-decoration: none;
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
  @media only screen and (max-width: 400px) {
    font-size: 1.35rem;
  }
`;

interface LinkProps {
  style: string | null;
  title: string;
  url: string;
  __typename: string;
}

declare global {
  interface Window {
    dataLayer: GtmDataLayer;
  }
}

const LinksLandingPage = ({ data }: { data: PageFields }) => {
  const [dataLayerArr, setDataLayerArr] =
    useState<{ event: string; link: string }[]>();
  const content = data.content;
  const linksArray = content.references[0].otherContent;
  //console.log('linksArray: ', linksArray);
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    if (globalDataLayer && typeof globalDataLayer === "object") {
      setDataLayerArr(globalDataLayer);
    }
  }, [data]);
  return (
    <Wrap>
      <Container>
        <Row>
          <Col lg={4} xs={12} className="centered col">
            <LogoWrap>
              <WMKLogo logoHeight="2.5rem" nameHeight="0rem" />
            </LogoWrap>
            <p className="at">@WatermarkAgency</p>
            <div className="desktop-only">
              <BasicSocialsRow />
            </div>
          </Col>
          <Col
            lg={4}
            xs={12}
            className="centered col"
            style={
              linksArray.length > 8 ? { justifyContent: "flex-start" } : {}
            }>
            {linksArray.map((link: LinkProps, i: number) => {
              return i < linksArray.length / 2 ? (
                <Link
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="centered"
                  key={`link-` + i}
                  onClick={
                    dataLayerArr
                      ? () => {
                          dataLayerArr.push({
                            event: "linkClickFromLinktreePage",
                            link: link.title
                          });
                        }
                      : undefined
                  }>
                  {link.title}
                </Link>
              ) : null;
            })}
          </Col>
          <Col
            lg={4}
            xs={12}
            className="centered col"
            style={
              linksArray.length > 8 ? { justifyContent: "flex-start" } : {}
            }>
            {linksArray.map((link: LinkProps, i: number) => {
              return i >= linksArray.length / 2 ? (
                <Link
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="centered"
                  key={`link-` + i}
                  onClick={
                    dataLayerArr
                      ? () => {
                          dataLayerArr.push({
                            event: "linkClickFromLinktreePage",
                            link: link.title
                          });
                        }
                      : undefined
                  }>
                  {link.title}
                </Link>
              ) : null;
            })}
            <div className="mobile-only">
              <BasicSocialsRow />
            </div>
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default LinksLandingPage;
